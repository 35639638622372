import { cva, cx, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'

export const button = cva('font-extra-bold whitespace-nowrap flex items-center justify-center tracking-wider text-center transition', {
  variants: {
    variant: {
      dard: '!disabled:cursor-not-allowed disabled:pointer-events-none disabled:bg-gray-100  disabled:text-gray-400 text-white hover:text-white shadow-md hover:shadow-lg',
      outlined:
        'disabled:pointer-events-none !disabled:cursor-not-allowed border border-solid disabled:text-gray-200 disabled:border-gray-200 disabled:bg-white',
      text: 'disabled:text-gray-400 !disabled:cursor-not-allowed disabled:pointer-events-none !disabled:bg-transparent disabled:hover:bg-transparent'
    },
    casing: {
      uppercase: 'uppercase',
      capitalize: 'capitalize',
      lowercase: 'lowercase',
      normal: 'normal-case'
    },
    color: {
      primary: '',
      secondary: '',
      danger: '',
      warning: '',
      info: '',
      success: '',
      white: '',
      gray: ''
    }
  },
  compoundVariants: [
    // Dard
    {
      variant: 'dard',
      color: 'primary',
      className: 'bg-primary-800 hover:bg-primary-900'
    },
    {
      variant: 'dard',
      color: 'secondary',
      className: 'bg-secondary-600  hover:bg-secondary-800'
    },
    {
      variant: 'dard',
      color: 'danger',
      className: 'bg-error-700 hover:bg-error-800'
    },
    {
      variant: 'dard',
      color: 'warning',
      className: 'bg-warning-600 hover:bg-warning-800'
    },
    {
      variant: 'dard',
      color: 'info',
      className: 'bg-info-600 hover:bg-info-800'
    },
    {
      variant: 'dard',
      color: 'success',
      className: 'bg-success-600 hover:bg-success-700'
    },
    {
      variant: 'dard',
      color: 'white',
      className: 'bg-white !text-gray-800 hover:bg-gray-100'
    },
    {
      variant: 'dard',
      color: 'gray',
      className: 'bg-gray-700 !text-white hover:bg-gray-800'
    },

    // Outlined
    {
      variant: 'outlined',
      color: 'primary',
      className: 'border-primary-300 text-primary-800 hover:text-primary-800 hover:bg-primary-50'
    },
    {
      variant: 'outlined',
      color: 'secondary',
      className: 'border-secondary-300 text-secondary-600 hover:text-secondary-600 hover:bg-secondary-50'
    },
    {
      variant: 'outlined',
      color: 'danger',
      className: 'border-error-300 text-error-700 hover:text-error-700 hover:bg-error-50'
    },
    {
      variant: 'outlined',
      color: 'warning',
      className: 'border-warning-400 text-warning-900 hover:text-warning-900 hover:bg-warning-50'
    },
    {
      variant: 'outlined',
      color: 'info',
      className: 'border-info-400 text-info-600 hover:text-info-600 hover:bg-info-50'
    },
    {
      variant: 'outlined',
      color: 'success',
      className: 'border-success-300 text-success-600 hover:text-success-600 hover:bg-success-50'
    },
    {
      variant: 'outlined',
      color: 'white',
      className: 'border-white border-opacity-50 text-white hover:text-white hover:bg-white hover:bg-opacity-10'
    },
    {
      variant: 'outlined',
      color: 'gray',
      className: 'border-gray-300 text-gray-600 hover:text-gray-600 hover:bg-gray-50'
    },

    // Text
    {
      variant: 'text',
      color: 'primary',
      className: 'text-primary-800 hover:text-primary-900 hover:bg-primary-50 focus-visible:bg-primary-50'
    },
    {
      variant: 'text',
      color: 'secondary',
      className: 'text-secondary-600 hover:text-secondary-600 hover:bg-secondary-50 focus-visible:bg-secondary-50'
    },
    {
      variant: 'text',
      color: 'danger',
      className: 'text-error-700 hover:text-error-700 hover:bg-error-50 focus-visible:bg-error-50'
    },
    {
      variant: 'text',
      color: 'warning',
      className: 'text-warning-900 hover:text-warning-900 hover:bg-warning-50 focus-visible:bg-warning-50'
    },
    {
      variant: 'text',
      color: 'info',
      className: 'text-info-600 hover:text-info-600 hover:bg-info-50 focus-visible:bg-info-50'
    },
    {
      variant: 'text',
      color: 'success',
      className: 'text-success-600 hover:text-success-600 hover:bg-success-50 focus-visible:bg-success-50'
    },
    {
      variant: 'text',
      color: 'white',
      className: 'text-white hover:bg-gray-700 focus-visible:bg-gray-50'
    },
    {
      variant: 'text',
      color: 'gray',
      className: 'text-gray-600 hover:bg-gray-50 focus-visible:bg-gray-50'
    }
  ],
  defaultVariants: {
    color: 'primary',
    variant: 'dard',
    casing: 'uppercase'
  }
})

export const buttonSize = cva('', {
  variants: {
    size: {
      sm: '',
      md: '',
      lg: ''
    },
    rounded: {
      default: 'rounded',
      none: '',
      full: 'rounded-full'
    }
  },
  compoundVariants: [
    {
      size: 'sm',
      className: 'px-3 min-h-[30px] text-xs'
    },
    {
      size: 'md',
      className: 'px-4 min-h-[38px] text-sm'
    },
    {
      size: 'lg',
      className: 'px-5 min-h-[46px] text-base'
    }
  ],
  defaultVariants: {
    rounded: 'default',
    size: 'md'
  }
})

export const buttonStyles = ({
  className,
  color,
  variant,
  rounded,
  size,
  casing
}: { className?: string } & VariantProps<typeof button> & VariantProps<typeof buttonSize>) =>
  cx(button({ color, variant, className, casing }), buttonSize({ rounded, size }))

type Intrinsic = Omit<JSX.IntrinsicElements['button'], 'color'>

export interface ButtonProps extends Intrinsic, VariantProps<typeof button>, VariantProps<typeof buttonSize> {
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, iconPosition = 'left', variant, color, rounded, size, casing, icon, ...props }, ref) => (
    <button className={cx(button({ color, variant, casing, class: className }), buttonSize({ size, rounded }))} {...props} ref={ref}>
      {icon && iconPosition === 'left' && <span className={cx({ 'mr-2': props.children })}>{icon}</span>}
      {props.children}
      {icon && iconPosition === 'right' && <span className={cx({ 'ml-2': props.children })}>{icon}</span>}
    </button>
  )
)

Button.displayName = 'Button'
